import classNames from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import CarePriorities from "./carePriorities/CarePriorities";
import NextStepChatView from "./chatView/NextStepChatView";
import Metrics from "./components/Metrics";
import { TaskPageContext } from "./TaskPage.context";
import styles from "./TaskPage.module.scss";

import ChevronDown from "~/assets/chevron-down-next-step.svg";
import Left from "~/assets/left-next-step.svg";
import LinkIcon from "~/assets/linkIcon.svg";
import Avatar from "~/components/avatar/Avatar";
import Button from "~/components/button/Button";
import Layout from "~/components/layout/Layout";
import Modal from "~/components/modal/Modal";
import PinnedNotes from "~/components/notes/PinnedNotes";
import UserNotes from "~/components/notes/UserNotesModal";
import PulseLoader from "~/components/pulseLoader/PulseLoader";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import {
  useCalculateRankingScore,
  useGetCareManagerTask,
  useGetCarePriorities,
  useGetUserContextInfo
} from "~/hooks/graphql/useCareManagerTasks";
import { NextRecommendedUserData } from "~/hooks/useApi/useNextRecommandedUser";
import useProgram from "~/hooks/useApi/useProgram";
import useUserDetail from "~/hooks/useApi/useUserDetail";
import { useChildrenTabIndexToggle } from "~/hooks/useChildrenTabIndexToggle";
import {
  useKeyboardShortcut,
  KeyboardShortcuts
} from "~/hooks/useKeyboardShortcut";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import {
  CarePriorities as Priorities,
  CarePriorityType
} from "~/typing/graphql/types";

const TaskPage = () => {
  const shortcuts = [
    {
      keys: KeyboardShortcuts.prevCarePriority,
      callback: () => handleNavigateToNextUser("previous")
    },
    {
      keys: KeyboardShortcuts.nextCarePriority,
      callback: () => handleNavigateToNextUser("next")
    },
    {
      keys: KeyboardShortcuts.nextUser,
      callback: () => handleNextUserClick()
    },
    {
      keys: KeyboardShortcuts.prevUser,
      callback: () => handlePrevUserClick()
    },
    {
      keys: KeyboardShortcuts.confirm,
      callback: () => {
        if (showTaskModal) {
          handleNavigateToNextUser();
        }
      }
    }
  ];

  useKeyboardShortcut(shortcuts);

  const { user_id = "" } = useParams();
  const { t } = useTranslation();

  const [copiedMessage, setCopiedMessage] = useState("");
  const [copiedMessageId, setCopiedMessageId] = useState("");

  const [userIndex, setUserIndex] = useState(0);
  const [nextUserDirection, setNextUserDirection] = useState<
    "previous" | "next"
  >("next");
  const [isLastUser, setIsLastUser] = useState(false);

  const [showTaskModal, setShowTaskModal] = useState(false);

  const [showNotesModal, setShowNotesModal] = useState(false);

  const [expandedNotes, setNotesExpanding] = useState(false);
  const [
    initialCarePriorities,
    setInitialCarePriorities
  ] = useState<Priorities>();

  const { recalculateRankingScore } = useCalculateRankingScore({
    userId: user_id
  });

  const navigate = useNavigate();

  const [
    nextStepUserInfo,
    setNextStepUserInfo
  ] = useState<NextRecommendedUserData>();

  const { trackNextStepTaken } = useAmplitudeTracking();

  /**
   * Get info about all the care manager tasks
   */
  const {
    careManagerTasks,
    status: careManagerTaskListStatus,
    isFetching: careManagerTaskListFetching
  } = useGetCareManagerTask();

  /**
   * Get info about current user
   */
  const {
    userContextInfo,
    isLoading: userContextLoading,
    isError: userContextError,
    status: userContextStatus
  } = useGetUserContextInfo(user_id);

  const { carePriorities, status: carePrioritiesStatus } = useGetCarePriorities(
    user_id
  );

  const {
    userDetail,
    isLoading: userDetailsLoading,
    isError: userDetailsError
  } = useUserDetail({
    userId: user_id,
    programCatalogItemId:
      userContextStatus === "success"
        ? userContextInfo?.programCatalogItemId
        : "",
    locale:
      userContextStatus === "success" && userContextInfo
        ? userContextInfo.locale
        : ""
  });

  const {
    program,
    isLoading: programIsLoading,
    isError: programIsError
  } = useProgram({
    programCatalogItemId:
      userContextStatus === "success"
        ? userContextInfo?.programCatalogItemId
        : "",
    locale:
      userContextStatus === "success" && userContextInfo
        ? userContextInfo.locale
        : ""
  });

  useEffect(() => {
    if (userContextInfo && userContextStatus === "success" && user_id) {
      setNextStepUserInfo({
        user_id: user_id,
        program_catalog_item_id: userContextInfo.programCatalogItemId,
        locale: userContextInfo.locale,
        reach_out_reasons: []
      });
    } else if (userContextStatus === "error") {
      recalculateRankingScore({ userId: parseInt(user_id) });
    }
  }, [userContextInfo, userContextStatus]);

  useEffect(() => {
    //Set the initial care priorities so that we can check them off as we go.
    //We only want to set the tabs once at the first successful fetch, so we don't remove the tabs when the care priorities are refetched.
    //If the user refreshes the page, the tabs will be set again.
    if (!initialCarePriorities && !!carePriorities) {
      setInitialCarePriorities(carePriorities);
    } else if (!!carePriorities && !!initialCarePriorities) {
      setInitialCarePriorities((prev) => {
        return {
          timeOfRanking: carePriorities?.timeOfRanking,
          priorities:
            prev?.priorities.map((priority) => {
              const unCompleted = carePriorities?.priorities.find(
                (completedPriority) =>
                  completedPriority?.type === priority?.type
              );

              return unCompleted ?? priority;
            }) ?? []
        };
      });
    }
  }, [carePriorities]);

  useEffect(() => {
    if (careManagerTasks && careManagerTaskListStatus === "success") {
      const index = careManagerTasks?.careManagerTaskDtos?.findIndex((task) => {
        if (!task) return false;
        return task.userId.toString() === user_id.toString();
      });

      setIsLastUser(
        index === careManagerTasks?.careManagerTaskDtos?.length - 1
      );
      setUserIndex(index ?? 0);
      setNextUserDirection("next");
    }
  }, [careManagerTasks, careManagerTaskListStatus, user_id]);

  const handlePrevUserClick = () => {
    if (userIndex === 0) return;

    setNextUserDirection("previous");
    setShowTaskModal(true);
  };

  const handleNextUserClick = () => {
    if (isLastUser) return;

    setNextUserDirection("next");
    setShowTaskModal(true);
  };

  const handleNavigateToNextUser = (direction?: "previous" | "next") => {
    setShowTaskModal(false);

    const nextUserIndex =
      (direction ?? nextUserDirection) === "next"
        ? userIndex + 1
        : userIndex - 1;

    const nextUser = careManagerTasks?.careManagerTaskDtos?.[nextUserIndex];

    if (!nextUser) return;

    handleTrackNextStepTaken(nextUser.userId);

    setCopiedMessage("");
    setCopiedMessageId("");

    navigate(`/tasks/${nextUser.userId}`);
  };

  const toggleNotes = () => {
    setNotesExpanding((prev) => !prev);
  };

  const handleTrackNextStepTaken = (nextUserId: string) => {
    const unfinishedCarePriorities =
      initialCarePriorities?.priorities
        .filter((priority) =>
          carePriorities?.priorities.every(
            (completedPriority) => completedPriority?.type !== priority?.type
          )
        )
        .map((priority) => priority?.type as string) ?? [];

    const initialCarePrioritiesArray = initialCarePriorities?.priorities.map(
      (priority) => priority?.type as string
    );

    trackNextStepTaken({
      carePriorities: initialCarePrioritiesArray ?? [],
      direction: nextUserDirection,
      from: user_id,
      to: nextUserId,
      unfinishedCarePriorities
    });
  };

  const userUrl = `/program/${userContextInfo?.programCatalogItemId}/locale/${userContextInfo?.locale}/users/${user_id}`;

  const userAndProgramDataLoaded =
    !userContextLoading &&
    !programIsLoading &&
    !programIsError &&
    !userContextError &&
    !userDetailsError &&
    !userDetailsLoading &&
    !isNaN(Number(user_id));

  const { parentRef: notesRef } = useChildrenTabIndexToggle({
    childrenHidden: !expandedNotes
  });

  if (!userAndProgramDataLoaded)
    return (
      <Layout>
        <section className={styles.loadingWrapper}>
          <PulseLoader inverted />
        </section>
      </Layout>
    );

  if (userAndProgramDataLoaded && !userContextInfo) {
    return (
      <Layout>
        <section className={styles.centered}>
          <div className={styles.error}>
            <p>{t("nextStep.errors.userError")}</p>
            <Link to={"/programs"}>
              <Button>{t("nextStep.errors.returnToProgram")}</Button>
            </Link>
          </div>
        </section>
      </Layout>
    );
  }

  return (
    <SentryErrorBoundary transactionName="CareManagerTask">
      <Layout>
        <TaskPageContext.Provider
          value={{
            nextUserInfo: nextStepUserInfo,
            program: program,
            getNextUser: () => void 0,
            registerActionForRecommendedUser: () => void 0,
            userDetailsLoading: userDetailsLoading,
            userDetails: userDetail,
            programLoading: programIsLoading,
            copiedMessage: copiedMessage,
            copiedMessageId: copiedMessageId,
            setCopiedMessage: setCopiedMessage,
            setCopiedMessageId: setCopiedMessageId,
            newNextStep: true
          }}
        >
          {userContextError && (
            <div className={styles.error}>
              <p>{t("nextStep.errors.fetchingUserInfo")}</p>
            </div>
          )}

          <div className={styles.header}>
            <Link to="/programs">
              <button
                className={styles.backToList}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate("/programs");
                  }
                }}
              >
                <img src={Left} alt="Back" /> {t("nextStep.backToList")}
              </button>
            </Link>
            <div className={styles.backNext}>
              {/* //TODO: Make a new button component from the Robin design system and then replace all buttons on this page with that */}
              <Button
                className={styles.button}
                inverted
                disabled={
                  userIndex === 0 ||
                  !carePriorities?.priorities.length ||
                  careManagerTaskListStatus === "pending" ||
                  careManagerTaskListFetching
                } //If the user is the first user in the list, disable the button
                onClick={handlePrevUserClick}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handlePrevUserClick();
                  }
                }}
              >
                {t("nextStep.previousUser")}
              </Button>
              <Button
                className={styles.button}
                inverted
                disabled={isLastUser}
                onClick={handleNextUserClick}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleNextUserClick();
                  }
                }}
              >
                {t("nextStep.nextUser")}
              </Button>
            </div>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.panel}>
              <div className={styles.userHeader}>
                <div className={styles.user}>
                  <Avatar user={userDetail?.user} size="lg" />
                  <div className={styles.userInfo}>
                    <div className={styles.userHeading}>
                      <p
                        title={userContextInfo?.userName}
                        className={styles.userName}
                      >
                        {userContextInfo?.userName}{" "}
                      </p>
                      <Link
                        to={userUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.viewUser}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            window.open(userUrl, "_blank");
                          }
                        }}
                      >
                        <span>{t("general.viewUser")}</span>
                        <img src={LinkIcon} alt="View user" />
                      </Link>
                    </div>

                    <div className={styles.program}>
                      <p>{program?.name}</p>
                      <p>
                        {`Week ${userContextInfo?.weekNumber}`}
                        {`, Day ${userContextInfo?.dayNumber}`}
                      </p>
                    </div>
                  </div>
                </div>
                <Button
                  className={styles.button}
                  size="sm"
                  inverted
                  onClick={toggleNotes}
                >
                  <span>
                    {t(expandedNotes ? "general.showLess" : "general.showMore")}
                  </span>
                  <img
                    src={ChevronDown}
                    className={classNames(styles.chevronIcon, {
                      [styles.rotated]: expandedNotes
                    })}
                  />
                </Button>
              </div>
              <div
                className={classNames(styles.notes, {
                  [styles.visible]: expandedNotes
                })}
                ref={notesRef}
              >
                <div className={styles.notesWrapper}>
                  <PinnedNotes showAllNotes={() => setShowNotesModal(true)} />
                </div>
              </div>
              <section className={styles.panelContent}>
                <CarePriorities
                  userContextInfo={userContextInfo}
                  userId={user_id}
                  carePriorities={initialCarePriorities}
                  onAllCarePrioritiesCompleted={() =>
                    handleNavigateToNextUser("next")
                  }
                />
                {nextStepUserInfo && userDetail && (
                  <Metrics
                    nextStepUserInfo={nextStepUserInfo}
                    userDetail={userDetail}
                  />
                )}
              </section>
            </div>
            <NextStepChatView />
          </div>
          {showTaskModal && (
            <Modal
              title={t("nextStep.modal.title")}
              onClose={() => setShowTaskModal(false)}
            >
              <>
                <p>{t("nextStep.modal.message1")}</p>
                <p>{t("nextStep.modal.message2")}</p>
                <div className={styles.modalButtons}>
                  <Button
                    className={`${styles.button} ${styles.cancel}`}
                    onClick={() => setShowTaskModal(false)}
                    disabled={carePrioritiesStatus !== "success"}
                  >
                    {t("general.cancel")}
                  </Button>
                  <Button
                    className={`${styles.button} ${styles.confirm}`}
                    onClick={() => handleNavigateToNextUser()}
                    inverted
                    disabled={carePrioritiesStatus !== "success"}
                  >
                    {t("nextStep.continue")}
                  </Button>
                </div>
              </>
            </Modal>
          )}
          {showNotesModal && (
            <UserNotes onClose={() => setShowNotesModal(false)} />
          )}
          {(userContextError || isNaN(Number(user_id))) && (
            <div className={styles.error}>
              <p>{t("nextStep.errors.userError")}</p>
              <Link to={"/programs"}>
                <Button className={styles.button}>
                  {t("nextStep.errors.returnToProgram")}
                </Button>
              </Link>
            </div>
          )}
        </TaskPageContext.Provider>
      </Layout>
    </SentryErrorBoundary>
  );
};

export default TaskPage;
